import * as Sentry from "@sentry/browser";
Sentry.init({
  dsn: "https://e640da5c7fff4beba0d87ae6fa2e35cf@o4504394414817280.ingest.sentry.io/4504983519428608",
  enabled: true,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0.1,
});
